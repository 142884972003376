export const Everyone_Role = "CS Hub - General User";

export const CS_Hub_AWS_Account_Request_Admin =
  "CS Hub - AWS Account Request Admin";

export const CS_Hub_AWS_Account_CIDR_Security =
  "CS Hub - AWS Account CIDR - Security";

export const CS_Hub_AWS_Notification_System =
  "CS Hub - AWS Notification System";

export const CS_Hub_AWS_Account_Search_Read_Only_Security =
  "CS Hub - AWS Account Search Read Only - Security";

export const CS_Hub_AWS_Dynamo_Search_Request_Admin =
  "CS Hub - AWS Dynamo Search Request Admin";

export const CS_Hub_ManagementUser_Monitoring =
  "CS Hub-ManagementUser-Monitoring";

export const CS_Hub_Security_Admin_Branch_1 = "CS Hub - Security View Only";

export const CS_Hub_Security_Admin_Branch_2 =
  "CS Hub - Security - AccountOwners";

export const CS_Hub_CloudEye = "CS Hub - CloudEye";

export const CS_Hub_CloudEye_Admin = "CS Hub-ManagementUser-";

export const CS_Hub_Cloud_Chargeback_Admins =
  "CS Hub - Cloud Chargeback Admins";

export const CS_Hub_GCS_Chargeback_Admins = "CS Hub - GCS-Chargeback-Admins";

export const CS_Hub_GCS_Billing_Admins = "CS Hub - GCS-Billing-Admins";

export const CS_Hub_Security_Tool_Exception_Approver_Group_2 =
  "CS Hub - Security Tool Exception Approver Group 2";
export const CS_Hub_Security_Tool_Exception_Approver_Group_1 =
  "CS Hub - Security Tool Exception Approver Group 1";
export const CS_Hub_PAM_Cost_Admins = "CS Hub - PAM Cost Admins";

export const CS_Hub_AIUsecases_Infosec = "CS Hub-AIUsecases-Infosec";

export const CS_Hub_AIUsecases_Risk = "CS Hub-AIUsecases-Risk";
