import React from "react";
import { Row, Col } from "react-bootstrap";
// import moment from 'moment';

// Import Icons
import backicon from "icons/back.svg";
import editicon from "icons/edit.svg";

const ViewBox = ({ data, closeViewParent, openEditParent }) => {
  return (
    <div className="view-edit-box-conatiner pb-5">
      <div className="view-edit-box-top-bar">
        <div className="view-edit-box-back-title-container">
          <img
            src={backicon}
            alt="Back"
            className="view-edit-box-back-btn"
            onClick={() => closeViewParent(false)}
          />
          <div>
            <h5 className="view-edit-box-title">AI Playground Request View</h5>
          </div>
        </div>
        <div className="view-edit-box-back-edit-container">
          {data.requestStatus === "Pending" && (
            <img
              src={editicon}
              alt="Edit"
              className="view-edit-box-edit-btn"
              onClick={() => {
                closeViewParent(false);
                openEditParent(true);
              }}
            />
          )}
        </div>
      </div>
      <div className="table-view-box-content mt-4 mb-5">
        <Row>
          {data?.lastupdategcs && (
            <>
              <Col md={12} className="mt-3">
                <div className="account-request-view-title-container">
                  <p className="account-request-view-title">
                    GCS Action Taken By
                  </p>
                </div>
              </Col>
              <Col md={12} className="mt-3">
                <div className="account-request-view-table-container">
                  <table width="100%">
                    <tbody className="account-request-view-table-tbody">
                      <tr>
                        <td
                          width="50%"
                          className="account-request-view-table-td-key"
                        >
                          Email
                        </td>
                        <td
                          width="50%"
                          className="account-request-view-table-td-value text-uppercase"
                        >
                          {data?.gcs_actiontakenby}
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="50%"
                          className="account-request-view-table-td-key"
                        >
                          Date(UTC)
                        </td>
                        <td
                          width="50%"
                          className="account-request-view-table-td-value text-uppercase"
                        >
                          {data?.lastupdategcs}
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="50%"
                          className="account-request-view-table-td-key"
                        >
                          Comments
                        </td>
                        <td
                          width="50%"
                          className="account-request-view-table-td-value text-uppercase"
                        >
                          {data?.gcsactioncomments || data?.actioncomments}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </>
          )}
          {data?.infosec_actiontakenby && (
            <>
              <Col md={12} className="mt-3">
                <div className="account-request-view-title-container">
                  <p className="account-request-view-title">
                    Infosec Action Taken By
                  </p>
                </div>
              </Col>
              <Col md={12} className="mt-3">
                <div className="account-request-view-table-container">
                  <table width="100%">
                    <tbody className="account-request-view-table-tbody">
                      <tr>
                        <td
                          width="50%"
                          className="account-request-view-table-td-key"
                        >
                          Email
                        </td>
                        <td
                          width="50%"
                          className="account-request-view-table-td-value text-uppercase"
                        >
                          {data?.infosec_actiontakenby}
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="50%"
                          className="account-request-view-table-td-key"
                        >
                          Date(UTC)
                        </td>
                        <td
                          width="50%"
                          className="account-request-view-table-td-value text-uppercase"
                        >
                          {data?.lastupdateinfo}
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="50%"
                          className="account-request-view-table-td-key"
                        >
                          Comments
                        </td>
                        <td
                          width="50%"
                          className="account-request-view-table-td-value text-uppercase"
                        >
                          {data?.infosecactioncomments}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </>
          )}

          <Col md={12} className="mt-3">
            <div className="account-request-view-title-container">
              <p className="account-request-view-title">Request Details</p>
            </div>
          </Col>
          <Col md={12} className="mt-3">
            <div className="account-request-view-table-container">
              <table width="100%">
                <tbody className="account-request-view-table-tbody">
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Global ID
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-uppercase"
                    >
                      {data.globalId}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Email Address
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-uppercase"
                    >
                      {data.email}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Application Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.applicationName}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Gen AI Service
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.cloudService}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Model
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.llmModel}
                    </td>
                  </tr>
                  {data?.cloudAccountNumber !== "" && (
                    <tr>
                      <td
                        width="50%"
                        className="account-request-view-table-td-key"
                      >
                        Cloud Account Number
                      </td>
                      <td
                        width="50%"
                        className="account-request-view-table-td-value text-capitalize"
                      >
                        {data.cloudAccountNumber}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      AI Feature Usage
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.applicationType === "Others (please specify)"
                        ? data.applicationType_others
                        : data.applicationType}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Type Of Environment
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.typeOfEnvironment}
                    </td>
                  </tr>

                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Application Name
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.applicationName}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Region
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.region}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Scope of Users
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.scopeOfUsers}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Types of Data
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.typeOfDataProcessed}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Data Type used for POC
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.dataTypeUsedForPOC}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Types of Tasks or Business Processes
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.typeOfTasks === "Others (please specify)"
                        ? data.typeOfTasks_others
                        : data.typeOfTasks}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      AI Usage Type
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.aiUsageType}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Outputs From The Tool Be Used
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.toolsDistribution}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Business Justification
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.businessJustification}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Quality Control Consent
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.qualityControlConsent}
                    </td>
                  </tr>

                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Use Case Description
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.useCaseDescription}
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="50%"
                      className="account-request-view-table-td-key"
                    >
                      Additional Comments
                    </td>
                    <td
                      width="50%"
                      className="account-request-view-table-td-value text-capitalize"
                    >
                      {data.additionalComments}
                    </td>
                  </tr>
                  {data?.supportingDocumentName !== "" && (
                    <tr>
                      <td
                        width="50%"
                        className="account-request-view-table-td-key"
                      >
                        Supporting Document
                      </td>
                      <td
                        width="50%"
                        className="account-request-view-table-td-value text-capitalize"
                      >
                        {data.supportingDocumentName}
                      </td>
                    </tr>
                  )}
                  {data?.lastupdateuser && (
                    <>
                      <tr>
                        <td
                          width="50%"
                          className="account-request-view-table-td-key"
                        >
                          Request Cancelled Date(UTC)
                        </td>
                        <td
                          width="50%"
                          className="account-request-view-table-td-value text-capitalize"
                        >
                          {data.lastupdateuser}
                        </td>
                      </tr>
                      <tr>
                        <td
                          width="50%"
                          className="account-request-view-table-td-key"
                        >
                          Action Comment
                        </td>
                        <td
                          width="50%"
                          className="account-request-view-table-td-value text-capitalize"
                        >
                          {data.actioncomments}
                        </td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Col>
        </Row>

        {data.billingOpco !== "" && (
          <Row>
            <Col md={12} className="mt-3">
              <div className="account-request-view-title-container">
                <p className="account-request-view-title">Billing Info</p>
              </div>
            </Col>
            <Col md={12} className="mt-3">
              <div className="account-request-view-table-container">
                <table width="100%">
                  <tbody className="account-request-view-table-tbody">
                    <tr>
                      <td
                        width="50%"
                        className="account-request-view-table-td-key"
                      >
                        Billing Operating Company
                      </td>
                      <td
                        width="50%"
                        className="account-request-view-table-td-value"
                      >
                        {data.billingOpco}
                      </td>
                    </tr>
                    <tr>
                      <td
                        width="50%"
                        className="account-request-view-table-td-key"
                      >
                        Billing Sub Operating Company
                      </td>
                      <td
                        width="50%"
                        className="account-request-view-table-td-value"
                      >
                        {data.billingSubOpco}
                      </td>
                    </tr>
                    <tr>
                      <td
                        width="50%"
                        className="account-request-view-table-td-key"
                      >
                        Billing Department
                      </td>
                      <td
                        width="50%"
                        className="account-request-view-table-td-value"
                      >
                        {data.billingDepartment}
                      </td>
                    </tr>
                    <tr>
                      <td
                        width="50%"
                        className="account-request-view-table-td-key"
                      >
                        Bill To
                      </td>
                      <td
                        width="50%"
                        className="account-request-view-table-td-value"
                      >
                        {data.billTo}
                      </td>
                    </tr>
                    {data.billingIo && (
                      <tr>
                        <td
                          width="50%"
                          className="account-request-view-table-td-key"
                        >
                          IO
                        </td>
                        <td
                          width="50%"
                          className="account-request-view-table-td-value"
                        >
                          {data.billingIo}
                        </td>
                      </tr>
                    )}
                    {data.costCenter && (
                      <tr>
                        <td
                          width="50%"
                          className="account-request-view-table-td-key"
                        >
                          Cost Center
                        </td>
                        <td
                          width="50%"
                          className="account-request-view-table-td-value"
                        >
                          {data.costCenter}
                        </td>
                      </tr>
                    )}
                    {data.gsid && (
                      <tr>
                        <td
                          width="50%"
                          className="account-request-view-table-td-key"
                        >
                          Cost Center
                        </td>
                        <td
                          width="50%"
                          className="account-request-view-table-td-value"
                        >
                          {data.gsid}
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td
                        width="50%"
                        className="account-request-view-table-td-key"
                      >
                        Billing Region
                      </td>
                      <td
                        width="50%"
                        className="account-request-view-table-td-value text-capitalize"
                      >
                        {data.billingRegion}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default ViewBox;
